<template>

  <div class="pa-4">

    <v-container fluid>

      <h2 text color="black" class="headline mb-4">ヘルプ</h2>

      <v-row>
        <v-col cols="12" lg="6">
          <h3 class="font-weight-regular mb-1">EG Holterシステム</h3>
          <v-card class="pa-6 pb-0">

            <h4 class="font-weight-regular">使い方</h4>
            <v-list dense class="pb-6">
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a href="/pdf/2304-manual.pdf" target="_blank">マニュアル（PDFを表示）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                      <a href="/pdf/2203-FAQ.pdf" target="_blank">よくあるお問い合わせ（PDFを表示）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <span v-if="$store.state.user.type == 'customer'">
                <v-list-item
                  link
                  v-for="video in this.$store.state.localVideos"
                  :key="video.title"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-video</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <a href="javascript:void(0)" @click="openVideoDialog(video.src, video.title, 'local')">動画：{{ video.title }}</a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <span v-if="$store.state.user.type == 'customer'">
                <v-list-item
                  link
                  v-for="video in this.$store.state.youtubeEmbedVideos"
                  :key="video.title"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-video</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <a href="javascript:void(0)" @click="openVideoDialog(video.src, video.title)">動画：{{ video.title }}</a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </v-list>

            <h4 class="font-weight-regular">利用規約等</h4>
            <v-list dense class="pb-6">
              <v-list-item
                link
                v-for="pdf in this.$store.state.termsOfServicePdfs"
                :key="pdf.name"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="`/pdf/${pdf.name}`" target="_blank">{{ pdf.label }}（PDFを表示）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="`/pdf/${this.$store.state.privacyPolicy.name}`" target="_blank">{{ this.$store.state.privacyPolicy.label }}（PDFを表示）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-information</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                      <router-link :to="{ name: 'about'}">ソフトウェアライセンス</router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <span v-if="$store.state.user.type == 'customer'">
              <h4 class="font-weight-regular">管理機能</h4>
              <v-list dense class="pb-6">
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>mdi-clipboard-text-clock-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link :to="{ name: 'operation-history'}">操作履歴</router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>favorite</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link :to="{ name: 'traceability'}">心電計トレーサビリティ</router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </span>

          </v-card>
        </v-col>

        <!-- <v-col cols="12" lg="6">
          <h3 class="font-weight-regular mb-1">マイホルターⅡ</h3>
          <v-card class="pa-6 pb-0">

            <h4 class="font-weight-regular">使い方</h4>
            <v-list dense class="pb-6">
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a href="https://myholter.net/v2/files/manual-Myholter2-2022_04_01.pdf" target="_blank">マニュアル（外部リンク先へ移動）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <h4 class="font-weight-regular">利用規約等</h4>
            <v-list dense class="pb-6">
              <v-list-item
                link
                v-for="pdf in this.$store.state.termsOfServiceForAnalysisPdfs"
                :key="pdf.name"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <a :href="`/pdf/${pdf.name}`" target="_blank">{{ pdf.label }}（PDFを表示）</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          </v-card>
        </v-col> -->
      </v-row>
    </v-container>

    <customer-video-dialog
      :dialogModel="videoDialogModel"
      :type="type"
      :src="src"
      :title="videoTitle"
      @closed="onDialogClosed"
    />

  </div>
</template>

<script>
import CustomerVideoDialog from '@/components/CustomerVideoDialog';

export default {
  name: 'Help',
  components: {
    CustomerVideoDialog,
  },
  data() {
    return {
      videoDialogModel: false,
      type: '',
      src: '',
      videoTitle: '',
    }
  },
  methods: {
    onDialogClosed() {
      this.videoDialogModel = false;
      this.type = '';
      this.src = '';
      this.videoTitle = '';
    },
    async openVideoDialog(src, title, type = '') {

      if (type === 'local') {
        src = await this.$storage.ref(src).getDownloadURL();
      }

      this.type = type;
      this.src = src;
      this.videoTitle = title;
      this.videoDialogModel = true;
    },
  },
}
</script>

<style scoped>
  .v-list-item__title a {
    white-space: normal;
    /*  以下は、text-body-2 そのもの */
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
    font-family: "Roboto", sans-serif !important;
  }
</style>