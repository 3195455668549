<template>
  <v-dialog
    content-class="customer-video-dialog"
    persistent
    v-model="dialogModel"
    max-width="944px"
  >
    <v-card flat class="pa-4">
      <v-card-title class="pb-6">{{ title }}</v-card-title>
      <v-card-text>
        <div v-if="type == 'local'" class="youtube">
          <video controls width="864" height="504" controlsList="nodownload" oncontextmenu="return false;">
            <source
              :src="src"
              type="video/mp4"
            />
          </video>
        </div>
        <div v-else class="youtube">
          <iframe
            width="896"
            height="504"
            :src="src + '?rel=0'"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary darken-1" text @click="closeDialog">動画を閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'CustomerVideoDialog',
  components: {
  },
  props: {
    dialogModel: Boolean,
    src: String,
    title: String,
    type: String,
  },
  mixins: [
  ],
  data() {
    return {
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closed');
    },
  },
  mounted() {
  },
}
</script>

<style>
  .customer-video-dialog.v-dialog {
    border-radius: 14px;
  }

  .customer-video-dialog .youtube {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .customer-video-dialog .youtube iframe {
    width: 100%;
    height: 100%;
  }
</style>
